<template>
  <BCard class="p-[11px]">
    <div class="text-black text-10 font-semibold mb-2">
      Pemutusan Hubungan Kerja
    </div>
    <BOverlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <ValidationObserver v-slot="{ invalid }">
        <BRow>
          <BCol md="8">
            <BRow>
              <BCol md="12">
                <BFormRow class="flex items-center mb-1">
                  <BCol cols="12">
                    <b-avatar
                      size="64px"
                      :src="initialUrl"
                    />
                    <span class="ml-2 text-black text-8 font-semibold capitalize">
                      {{ talentName }}
                    </span>
                  </BCol>
                </BFormRow>
              </BCol>
              <BCol md="12">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Pilih Jenis Pemutusan <span class="text-danger">*</span>
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    name="Jenis Pemutusan"
                    rules="required"
                  >
                    <BFormRadioGroup
                      v-model="offStatus"
                      class="mt-50"
                      :options="terminationOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
              <BCol md="12">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    URL Document <span class="text-danger">*</span>
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    name="URL Document"
                    :rules="{ required: true, regex: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }"
                  >
                    <b-form-input
                      v-model="offDocumentUrl"
                      class="text--blue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
              <BCol md="12">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    URL Lainnya
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    name="URL Lainnya"
                    :rules="{ regex: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }"
                  >
                    <b-form-input
                      v-model="offNotesUrl"
                      class="text--blue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
            </BRow>
          </BCol>
        </BRow>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            v-b-modal.modalConfirmation
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </ValidationObserver>
    </BOverlay>
    <ModalConfirmation
      :loadingSubmit="loadingSubmit"
      @confirm="submit()"
    />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import ModalConfirmation from './ModalConfirmation.vue'
import { terminationOptions } from './config'

export default {
  data() {
    return {
      required,
      loading: false,
      loadingSubmit: false,
      offStatus: '',
      terminationOptions,
      offDocumentUrl: '',
      offNotesUrl: '',
      detailData: {},
      talentName: '',
      initialUrl: '',
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    ModalConfirmation,
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        off_status: this.offStatus,
        off_document_url: this.offDocumentUrl,
        off_notes_url: this.offNotesUrl,
      }

      const url = `v1/talents/${this.$route.params.id}/off_action`
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil memberhentikan talent'

          alertSuccess(text)
          this.$router.back()
        })
        .catch(error => {
          alertError(error)
          this.loadingSubmit = false
        })
    },
    async getDetailData() {
      const url = `v1/talents/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          /* eslint-disable camelcase */
          const { full_name, photo_profile_url } = data

          this.talentName = full_name
          this.initialUrl = photo_profile_url
        })
        .catch(err => {
          alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
  mounted() {
    this.getDetailData()
  },
}
</script>
